export enum Tools {
    None,
    DailyMission,
    Tools,
    EntropiaMapNew,
    TaxiService,
    RandomFun
}

export enum DailyMissionPlanet {
    Calypso,
    Cyrene
}