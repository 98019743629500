import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import { Footer } from '../components/common/footer';
import { DailyMissions } from '../components/projects/DailyMissions/DailyMissions';
import { EntropiaMapNew } from '../components/projects/EntropiaMap/EntropiaMapNew';
import { Tools } from '../models/ToolsEnum';
import { TeleportFinder } from '../components/projects/TeleportFinder/TeleportFinder';
// import { AuctionCalc } from '../components/projects/AuctionCalculator/AuctionCalc';
// import { SlotMachine } from '../components/projects/SlotMachine/SlotMachine';
// import { TaxiService } from '../components/projects/TaxiService/TaxiService';
// import { FF14Weeklies } from '../components/projects/FF14Weeklies/FF14Weeklies';
import EntropiaMapBackgroundIamge from '../images/entropia-map.png';

const Main = styled.main({
    display: 'block',
    position: 'relative',
    minWidth: '100%',
    minHeight: '100%',
    height: '100%',
    paddingTop: '0'
});

const CardContainer = styled.div({
    position: 'relative',
    width: '100%',
    display: 'block',
    margin: '0 auto',
    backgroundColor: 'black',
    boxSizing: 'border-box',
    padding: '0',

    '&#tools': {
        backgroundColor: 'rgba(20,20,20,1)',
        minHeight: '500px'
    },

    '&#media': {
        display: 'flex',
        flexDirection: 'row',
    }
});

const ToolList = styled.div({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    // borderBottom: '1px solid white',
    maxWidth: '1000px',
    margin: '0 auto',

    'div': {
        padding: '20px',
        fontSize: 20,
        textDecoration: 'none',
        color: 'white',

        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline'
        }
    }
})

const ToolListContainer = styled.div({
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    margin: '0 auto',

    'div': {
        fontSize: '1.5rem',
        textDecoration: 'none',
    }
})

const ToolListCard = styled.div({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '150px',
    border: '2px solid white',
    borderRadius: '5px',
    padding: '20px',
    marginTop: '2em',
    transition: 'transform 2s ease-out',

    ':hover': {
        'h2': {
            transition: 'transform 0.5s ease',
            transform: 'translateY(-50px)'
        }
    },

    'h2': {
        marginTop: '0'
    },
})

// NEW CARD
const NewCard = styled.div<{ textDirection: string }>(({ textDirection }) => ({
    display: 'flex',
    margin: '0 auto',
    width: '400px',
    height: '100px',
    backgroundImage: 'linear-gradient(163deg, #ff00ff 0%, #3700ff 100%)',
    borderRadius: '20px',
    textAlign: 'center',
    transition: 'all 0.25s cubic-bezier(0, 0, 0, 1)',
    marginTop: '2rem',

    ':hover': {
        boxShadow: '0px 0px 30px 1px rgba(204, 0, 255, 0.3)',

        '.new-card-text-container': {
            height: '100%',
            borderRadius: '20px',
            color: 'white',
            marginLeft: textDirection == 'left' ? '-15%' : '30%',
            transition: 'margin 0.5s ease',

            'span': {
                '&::after': {
                    content: ">>"
                }
            }
        },

        '.new-card-content': {
            backgroundColor: '#1a1a1a',
        }
    }
}));

const NewCardContent = styled.div({
    display: 'flex',
    width: '400px',
    height: '100px',
    backgroundColor: '#fff',
    borderRadius: '15px',
    transition: 'all 0.25s cubic-bezier(0, 0, 0, 1)',
    cursor: 'pointer',
    alignItems: 'center',

    ':hover': {
        transform: 'scale(0.98)',
        borderRadius: '20px',
    }
})

const NewCardTextContainer = styled.div({
    width: '100%',
    height: '30%',
    color: '#1a1a1a',

    'span': {
        position: 'relative',
        top: 'calc(50% - 1.2rem)',
        margin: '0 auto',
        transition: 'margin 2s ease',
    },
})

// const AdSpaceHorizontal = styled.div({
//     display: 'block',
//     maxWidth: 'fit-content',
//     minHeight: '50px',
//     border: '3px dashed white',
//     width: '100%',
//     margin: '0 auto',
//     color: 'white',
//     padding: '20px',

//     'h2': {
//         marginTop: 0
//     }
// })

export const Homepage: React.FC = () => {
    const [currTool, setCurrTool] = useState(Tools.None);
    const history = useHistory();

    const displayToolContent = () => {
        var currentPath = history.location.pathname.split('/').filter(Boolean).pop();
        switch (currentPath) {
            case "daily":
                return <DailyMissions />
            case "map":
                return <EntropiaMapNew />
            // case Tools.Tools:
            //     return <AuctionCalc />
            // case Tools.TaxiService:
            //     return <TaxiService />
            // case Tools.RandomFun:
            //     return <SlotMachine />
            default:
                return <></>
        }
    }

    return <Main>
        {/* <AdSpaceHorizontal>
            <h2>WANT TO ADVERTISE? CONTACT ME IN-GAME</h2>
            <span>PONTUS RIPCRAZE VIRUS</span>
        </AdSpaceHorizontal> */}
        {currTool === Tools.None && <TeleportFinder />}
        {/* <p style={{color: 'white', maxWidth: '700px', margin: '1em auto', backgroundColor: 'rgba(20,20,20,0.7)', padding: '1em'}}>
            To keep this website ad-free and up running you can help either by donating through PayPal <a style={{color: 'lightblue'}} target='_blank' rel='noopener noreferrer' href='https://www.paypal.com/paypalme/Ripcraze'>HERE</a> or by making a donation in-game to 'Pontus Ripcraze Virus'. 
            <br/> Thank you so much for using the website and supporting me in my work for this amazing game.
        </p> */}

        {/* CHAT GPT FF14 WEEKLIES LIST */}
        {/* <div style={{backgroundColor: 'rgba(20,20,20,1)', color: 'white', width: 'fit-content', padding: '0', margin: '0 auto 1em auto', border: '2px solid white', borderRadius: '12px'}}>
            <FF14Weeklies />
        </div> */}

        {/* <div style={{ backgroundColor: 'rgba(20,20,20,1)', color: 'white', width: 'fit-content', padding: '1em', margin: '0 auto 1em auto', border: '2px solid white', borderRadius: '12px' }}>
            <SlotMachine />
        </div> */}

        <CardContainer id="tools">
            {/* <ToolList>
                <div onClick={() => {
                    setCurrTool(Tools.DailyMission);
                    history.push("/daily");
                }}>
                    DAILY MISSION TRACKER
                </div>
                <div onClick={() => {
                    setCurrTool(Tools.EntropiaMapNew);
                    history.push("/map");
                }}>
                    ENTROPIA MAP
                </div>
            </ToolList> */}
            {currTool !== Tools.None &&
                <div>
                    {displayToolContent()}
                </div>
            }

            {currTool === Tools.None && (
                <>
                    <ToolListContainer id="tool-card-container">
                        <NewCard textDirection={'left'} onClick={() => {
                            setCurrTool(Tools.DailyMission);
                            history.push("/daily");
                        }}>
                            <NewCardContent className='new-card-content'>
                                <NewCardTextContainer className='new-card-text-container'>
                                    <span>Daily Missions</span>
                                </NewCardTextContainer>
                            </NewCardContent>
                        </NewCard>
                        <NewCard textDirection={'right'} onClick={() => {
                            setCurrTool(Tools.DailyMission);
                            history.push("/map");
                        }}>
                            <NewCardContent className='new-card-content'>
                                <NewCardTextContainer className='new-card-text-container'>
                                    <span>Entropia Map</span>
                                </NewCardTextContainer>
                            </NewCardContent>
                        </NewCard>
                        {/* <ToolListCard className="tool-card" onClick={() => {
                            setCurrTool(Tools.DailyMission);
                            history.push("/daily");
                        }}>
                            <h2>Daily Missions</h2>
                        </ToolListCard>
                        <ToolListCard style={{ backgroundImage: EntropiaMapBackgroundIamge }} className="tool-card" onClick={() => {
                            setCurrTool(Tools.EntropiaMapNew);
                            history.push("/map");
                        }}>
                            <h2>Entropia Map</h2>
                        </ToolListCard> */}
                    </ToolListContainer>
                </>)
            }
        </CardContainer>
        <Footer />
    </Main>
}