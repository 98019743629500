import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Theme } from './config/theme';
import * as mainTheme from './config/main';
import { ThemeProvider } from 'emotion-theming';
import App from './App';

interface IProps {
  theme?: Theme;
}

const MainApp: React.FC<IProps> = ({ theme = mainTheme }) => (
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>
);

ReactDOM.render(<MainApp />, document.getElementById('root'));
