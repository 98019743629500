import styled from '@emotion/styled';
import React from 'react';
import { useHistory } from 'react-router-dom';

const Main = styled.header({
    backgroundColor: 'rgba(20,20,20,1)',
    width: '100%',
    top: 0,
    minHeight: '40px',
    maxHeight: '40px',
    margin: 0,
    padding: '10px 0 15px 0',

    'h2': {
        color: 'white',
        margin: 0,
        position: 'absolute',
        left: 15,
        lineHeight: '40px'
    },

    'a': {
        textDecoration: 'none',
        color: 'white'
    }
});

const HeaderTitle = styled.h2({
    span: {
        fontSize: '0.5em'
    }
});

const HeaderLinks = styled.p({
    lineHeight: '40px',
    fontSize: '1em',
    margin: '4px 15px 0 0',
    textAlign: 'right',
    color: 'white',

    a: {
        '&:not(:last-of-type)': {
            marginRight: '10px'
        },
        '&:hover': {
            textDecorationLine: 'underline',
        }
    }
});

export const Header: React.FC = () => {
    const history = useHistory();

    return (
        <Main>
            <HeaderTitle>
                <a href='/' onClick={() => { history.push("/daily"); }}>RIPCRAZE <span>BY PONTUS RIPCRAZE VIRUS</span></a>
            </HeaderTitle>
            <HeaderLinks>
                <a href='/' rel="noopener noreferrer" onClick={() => { history.push("/daily"); }}>
                    Home
                </a>
                <a target='_blank' rel="noopener noreferrer" href="https://www.planetcalypsoforum.com/forum/index.php?members/ripcraze.34603/">
                    Forum
                </a>
                <a target='_blank' rel="noopener noreferrer" href="http://www.entropialife.com/Profile.aspx?P=108771">
                    EntropiaLife
                </a>
            </HeaderLinks>
        </Main>)
}