import styled from '@emotion/styled';
import React, { useContext, useEffect, useState } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import './App.css';
import { Cookies } from './components/common/cookies';
import { Header } from './components/common/header';
import { AppConfigContext, AppConfigContextProvider } from './config/AppConfigContext';
import { AppContextProvider } from './context/AppContextProvider';
import { ILocaleContext, LocaleContext } from './context/locale-context';
import { readCookieByName } from './hooks/useCookies';
import { useLocalStorage } from './hooks/useLocalstorage';
import { Homepage } from './screens/Homepage';
import { EntropiaMapNew } from './components/projects/EntropiaMap/EntropiaMapNew';

const AppContainer = styled.div<{}>(({ theme }: any) => ({
  textAlign: 'center',
  minHeight: '100vh',
  color: theme.colors.black,
  minWidth: 350,
  background: 'rgba(20,20,20,1)',
  boxSizing: 'border-box',
}));

const App: React.FC = () => {
  const appConfigContext = useContext(AppConfigContext);
  const [locales] = useState(appConfigContext.i18n.availableLocales);
  const [activeLocale, setActiveLocale] = useLocalStorage('activeLocale', appConfigContext.i18n.activeLocale);
  const [hasConsent, setHasConsent] = useState(readCookieByName('consent'))

  const localeContext: ILocaleContext = {
    activeLocale,
    locales,
    changeLocale: (locale: string) => {
      setActiveLocale(locale);
    }
  };

  useEffect(() => {
    window.document.documentElement.lang = activeLocale;
  }, [activeLocale]);

  useEffect(() => {
    setHasConsent(hasConsent);
  }, [setHasConsent, hasConsent]);

  return (
    <AppContainer>
      <Router>
        <AppConfigContextProvider>
          <LocaleContext.Provider value={localeContext}>
            <AppContextProvider>
              <Header />
              <Switch>
                <Route component={Homepage} exact path="/" />
                <Route component={Homepage} path="*" />
                <Route component={EntropiaMapNew} path={"/map"} />
                {/* <Route component={EntropiaMapNew} path="/map" />
                <Route component={EntropiaMap} path="/oldmap" />
                <Route component={DailyMissions} path="/dailytoken" />
                <Route component={AuctionCalc} path="/craftcalc" />*/}
                {/* <Route component={TaxiService} path="/taxi" /> */}
                {hasConsent.length <= 0 && <div onClick={() => setHasConsent('consent')}><Cookies /></div>}
              </Switch>
            </AppContextProvider>
          </LocaleContext.Provider>
        </AppConfigContextProvider>
      </Router>
    </AppContainer>
  );
}

export default App;
